import { FC } from "react";
import { FEATURES } from "@/config";
import Card from "@/components/Card";

interface FeaturesProps {}

const Features: FC<FeaturesProps> = () => {
  return (
    <section className="bg-primary" data-navlink="Features">
      <div className="mx-auto max-w-7xl px-4 py-12 text-slate-800">
        <div className="mb-8 flex flex-col items-start justify-between gap-4 md:flex-row md:items-end md:px-8">
          <h2 className="max-w-lg text-4xl font-bold md:text-5xl">
            Grow faster with our
            <span className="text-secondary-dark"> all in one solution</span>
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
          {FEATURES.map((feature, index) => (
            <Card.Feature key={["feature", index].join(":")} {...feature} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
