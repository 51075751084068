import Card from "@/components/Card";
import { FaAccusoft } from "react-icons/fa";

const Dashboard = () => {
  return (
    <div className="h-screen bg-background text-slate-300">
      <div className="container mx-auto py-8">
        <div className="mx-auto">Logo goes here</div>
        <div className="grid grid-cols-3 gap-4">
          <Card.Dashboard icon={FaAccusoft} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
