import { motion, AnimatePresence } from "framer-motion";
import { FC } from "react";
import Collapsible from "@/components/Collapsible/Collapsible";
import { QUESTIONS } from "@/config";

interface FAQProps {}

const Questions = () => {
  return (
    <div className="mx-auto mt-12 max-w-3xl">
      <AnimatePresence mode="wait">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{
            duration: 0.5,
            ease: "backIn",
          }}
          className="space-y-4"
        >
          {QUESTIONS.map(({ question, answer }, index) => (
            <Collapsible key={question} title={question}>
              {answer}
            </Collapsible>
          ))}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

const FAQ: FC<FAQProps> = () => {
  return (
    <section
      className="relative overflow-hidden bg-slate-900 px-4 py-12 text-slate-50"
      data-navlink="FAQ"
    >
      <div className="relative z-10 flex flex-col items-center justify-center">
        <span className="mb-8 bg-gradient-to-r from-primary/80 to-primary bg-clip-text font-medium text-transparent">
          Let's answer some questions
        </span>
        <span className="mb-8 text-5xl font-bold">FAQs</span>
      </div>

      <span className="absolute -top-[350px] left-[50%] z-0 h-[500px] w-[600px] -translate-x-[50%] rounded-full bg-gradient-to-r from-primary/20 to-primary/30 blur-3xl" />
      <Questions />
    </section>
  );
};

export default FAQ;
