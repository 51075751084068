import { Outlet } from "react-router";
import Navigation from "@/components/Navigation/Navigation";
import Footer from "@/components/Footer/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LandingLayout = () => {
  return (
    <div className="dark">
      <Navigation />
      <main className="h-screen bg-background text-white">
        <Outlet />
        <Footer />
      </main>
      <ToastContainer
        theme="dark"
        autoClose={10000}
        pauseOnHover
        newestOnTop
        closeButton
      />
    </div>
  );
};

export default LandingLayout;
