import axios, { AxiosPromise } from "axios";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

console.log({
  API_URL: process.env.REACT_APP_API_URL,
});

const api = {
  addDiscountCode: (code: string): AxiosPromise<Api.DiscountCodeResponse> =>
    client.get(`/discount_code/${code}`),
  createOrder: (
    orderData: Pick<Api.CreateOrderRequest, "email" | "size" | "discount_code">
  ): AxiosPromise<Api.CreateOrderResponse> =>
    client.post("/create_order", {
      ...orderData,
      order_type: "1",
      payment_method: "crypto",
      order_from: "web",
    }),
  getDashboard: (
    orderId: string,
    emailOrId: string
  ): AxiosPromise<Api.DashboardResponse> =>
    client.get(`/dashboard/${orderId}/${emailOrId}`),
};

export default api;
