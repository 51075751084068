import { motion } from "framer-motion";
import { FC } from "react";
import { IconType } from "react-icons";

interface FeatureCardProps {
  title: string;
  description: string;
  icon: IconType;
}

export const FeatureCard: FC<FeatureCardProps> = ({
  title,
  description,
  icon: Icon,
}) => {
  return (
    <div className="group relative mx-auto w-full max-w-sm overflow-hidden rounded-lg bg-primary/90 p-0.5 transition-all duration-500 hover:scale-[1.01] hover:bg-primary/70">
      <div className="relative z-10 flex flex-col items-center justify-center overflow-hidden rounded-[7px] bg-background/90 p-8 transition-colors duration-500 group-hover:bg-background/70">
        <Icon className="relative z-10 mb-10 mt-2 rounded-full border-2 border-primary bg-background p-4 text-7xl text-primary" />

        <h4 className="relative z-10 mb-4 w-full text-3xl font-bold text-slate-50">
          {title}
        </h4>
        <p className="relative z-10 text-slate-400 w-full">{description}</p>
      </div>

      <motion.div
        initial={{ rotate: "0deg" }}
        animate={{ rotate: "360deg" }}
        style={{ scale: 1.75 }}
        transition={{
          repeat: Infinity,
          duration: 3.5,
          ease: "linear",
        }}
        className="absolute inset-0 z-0 bg-gradient-to-br from-primary-200 via-primary-200/0 to-primary-200 opacity-0 transition-opacity duration-500 group-hover:opacity-100"
      />
    </div>
  );
};

export default FeatureCard;
