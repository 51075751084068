import { motion } from "framer-motion";
import { FC, MouseEventHandler } from "react";

export interface NavLinkProps {
  label: string;
  onClick: (label: string) => void;
}

const NavLink: FC<NavLinkProps> = ({ label, onClick }) => {
  const handleOnClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    onClick(label);
  };

  return (
    <li>
      <a href="/" className="block overflow-hidden" onClick={handleOnClick}>
        <motion.div
          whileHover={{ y: -40 }}
          transition={{ ease: "backInOut", duration: 0.5 }}
          className="h-[40px] px-2"
        >
          <span className="flex h-[40px] items-center uppercase font-bold">
            {label}
          </span>
          <span className="flex h-[40px] items-center uppercase font-bold text-primary">
            {label}
          </span>
        </motion.div>
      </a>
    </li>
  );
};

export default NavLink;
