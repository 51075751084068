import { GLOBALS } from "@/config";
import { useCallback, useMemo } from "react";

const usePrice = (size: number, discount?: number) => {
  const value = useMemo(() => {
    const totalPrice = size * GLOBALS.PRICE_PER_GB;
    if (!discount) return totalPrice;

    return totalPrice - (totalPrice * discount) / 100;
  }, [size, discount]);

  const toCurrency = useCallback(() => {
    return [GLOBALS.CURRENCY_SYMBOL, value.toFixed(0)].join(" ");
  }, [value]);

  return {
    value,
    toCurrency,
  };
};

export default usePrice;
