import { Outlet } from "react-router";
import Hero from "@/components/Heros";

import FAQ from "./components/FAQ";
import Features from "./components/Features";
import Plans from "./components/Plans";
import Contact from "./components/Contact";

const Landing = () => {
  return (
    <>
      <Hero.Grid>
        <h1 className="text-center text-4xl font-black uppercase text-white sm:text-5xl md:text-6xl">
          Simple, reliable residential proxies.
        </h1>
        <p className="mb-6 mt-4 max-w-5xl text-center text-lg font-light text-white md:text-xl">
          We provide the cleanest and most reliable residential proxies.
          <br /> At the moment, our pool contains more than 10,000,000 IP
          addresses from all around the world.
          <br />
          Perfect down to ISP targeting, and a lot more!
        </p>
        <button className="pointer-events-auto bg-primary px-4 py-2 text-xl font-bold uppercase text-neutral-950 mix-blend-difference drop-shadow-lg">
          SEE MORE
        </button>
      </Hero.Grid>
      <Features />
      <Plans />
      <FAQ />
      <Contact />
      <Outlet />
    </>
  );
};

export default Landing;
