import { Navigate, createBrowserRouter } from "react-router-dom";
import LandingLayout from "@/layouts/Landing";
import Landing from "@/views/Landing/Landing";
import CheckoutModal from "./views/Landing/modals/CheckoutModal";
import Dashboard from "./views/Dashboard/Dashboard";

export default createBrowserRouter([
  {
    path: "",
    element: <LandingLayout />,
    children: [
      {
        path: "",
        element: <Landing />,
        children: [
          {
            path: "checkout",
            element: <CheckoutModal />,
          },
        ],
      },
    ],
  },
  {
    path: "dashboard/:orderId/:emailOrId",
    element: <Dashboard />,
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
]);
