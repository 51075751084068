import { FC } from "react";
import { motion } from "framer-motion";
import { CONTACTS } from "@/config";

interface ContactProps {}

const Contact: FC<ContactProps> = () => {
  return (
    <section className="bg-primary" data-navlink="Contact">
      <div className="mx-auto max-w-7xl px-4 py-12 text-slate-800">
        <div className="flex flex-col items-center justify-between gap-4 md:flex-row md:px-8">
          <h2 className="max-w-lg text-4xl font-bold md:text-5xl">
            Get in
            <span className="text-secondary-dark"> touch</span>
          </h2>
          <ul className="unstyled flex gap-4 antialiased">
            {CONTACTS.map(({ title, href, color, icon: Icon }) => (
              <motion.li
                key={href}
                className="bg-background flex rounded-full"
                whileHover={{
                  scale: 1.1,
                }}
              >
                <motion.a
                  href={href}
                  target="_blank"
                  className="p-4 text-slate-200"
                  title={title}
                  whileHover={{
                    color: color,
                  }}
                >
                  <Icon size={36} />
                </motion.a>
              </motion.li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Contact;
