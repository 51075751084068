import { FC } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import usePrice from "@/hooks/usePrice";

interface PlanCardProps {
  name: string;
  size: number;
  features?: string[];
}

const PlanCard: FC<PlanCardProps> = ({ name, size, features = [] }) => {
  const navigate = useNavigate();
  const price = usePrice(size);

  const handleButtonClick = () => {
    navigate("/checkout", {
      state: {
        size,
      },
    });
  };

  return (
    <div className="w-full bg-white p-6 border-[1px] border-slate-300 rounded-xl">
      <p className="text-2xl font-bold mb-2">{size} GB</p>
      <p className="text-lg mb-6">{name}</p>
      <p className="text-6xl font-bold mb-8">{price.toCurrency()}</p>
      {features.map((feature, index) => (
        <div className="flex items-center gap-2 mb-2" key={index}>
          <svg
            width="20"
            height="15"
            viewBox="0 0 20 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="shrink-0"
          >
            <path
              d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
              fill="black"
            />
          </svg>
          <span className="text-base">{feature}</span>
        </div>
      ))}

      <motion.button
        whileHover={{ scale: 1.015 }}
        whileTap={{ scale: 0.985 }}
        className="w-full py-4 mt-8 font-semibold bg-primary rounded-lg uppercase"
        onClick={handleButtonClick}
      >
        Buy now
      </motion.button>
    </div>
  );
};

export default PlanCard;
