import DashboardCard from "./DashboardCard";
import FeatureCard from "./FeatureCard";
import PlanCard from "./PlanCard";

const Card = {
  Feature: FeatureCard,
  Plan: PlanCard,
  Dashboard: DashboardCard,
} as const;

export default Card;
