import { FC, useEffect, useRef, useState } from "react";
import Logo from "./Logo";
import NavLink from "./NavLink";
import clsx from "clsx";

interface NavigationProps {}

const Navigation: FC<NavigationProps> = () => {
  const navRef = useRef<HTMLElement>(null);
  const [links, setLinks] = useState<any[]>([]);

  const handleNavlinkClick = (label: string) => {
    if (!navRef.current) return;

    const linkIndex = links.findIndex((link) => link.label === label);
    if (linkIndex === -1) return;

    const link = links[linkIndex];

    window.scrollTo({
      top: link.scrollTop - navRef.current.clientHeight,
      behavior: "smooth",
    });
  };

  const handleLogoClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (!navRef.current) return;

    const sections = document.querySelectorAll("[data-navlink]");
    if (!sections.length) return;

    const links = Array.from(sections).map((s) => {
      const { top } = s.getBoundingClientRect();

      return {
        label: (s as any).dataset.navlink,
        scrollTop: top,
      };
    });
    setLinks(links);
  }, [navRef]);

  return (
    <nav
      ref={navRef}
      className={clsx(
        "flex fixed w-full items-center gap-6 bg-neutral-900 p-2 text-sm text-neutral-500 shadow-md z-50"
      )}
    >
      <Logo onClick={handleLogoClick} />
      <ul className="unstyled flex mx-auto">
        {links.map((link) => (
          <NavLink
            key={["link", link.label].join(":")}
            label={link.label}
            onClick={handleNavlinkClick}
          />
        ))}
      </ul>
    </nav>
  );
};

export default Navigation;
