import { FaDiscord, FaTelegramPlane } from "react-icons/fa";
import { FiCloudLightning, FiCloudRain } from "react-icons/fi";

export const GLOBALS = {
  BANDWIDTH: {
    MIN: 3,
    MAX: 1000,
  },
  PRICE_PER_GB: 3,
  CURRENCY_SYMBOL: "$",
} as const;

export const FEATURES = [
  {
    title: "Feature 1",
    description: "Lorem ipsum 1",
    icon: FiCloudLightning,
  },
  {
    title: "Feature 2",
    description: "Lorem ipsum 2",
    icon: FiCloudLightning,
  },
  {
    title: "Feature 3",
    description: "Lorem ipsum 3",
    icon: FiCloudRain,
  },
];

export const PLANS = [
  {
    name: "Starter",
    size: 3,
    features: ["Feature 1", "Feature 2", "Feature 3"],
  },
  {
    name: "Pro",
    size: 50,
    features: ["Feature 1", "Feature 2", "Feature 3"],
  },
  {
    name: "Premium",
    size: 1000,
    features: ["Feature 1", "Feature 2", "Feature 3"],
  },
];

export const QUESTIONS = [
  {
    question: "What is web deeeeevelopment?",
    answer:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sint tempora quasi eligendi distinctio, mollitia porro repudiandae modi consectetur consequuntur perferendis!",
  },
  {
    question: "How do I know if I need it?",
    answer:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sint tempora quasi eligendi distinctio, mollitia porro repudiandae modi consectetur consequuntur perferendis!",
  },
  {
    question: "What does it cost?",
    answer:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sint tempora quasi eligendi distinctio, mollitia porro repudiandae modi consectetur consequuntur perferendis!",
  },
  {
    question: "What about SEO?",
    answer:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sint tempora quasi eligendi distinctio, mollitia porro repudiandae modi consectetur consequuntur perferendis!",
  },
];

export const CONTACTS = [
  {
    title: "Discord",
    href: "https://discord.gg/beeproxies",
    icon: FaDiscord,
    color: "#7289DA"
  },
  {
    title: "Telegram",
    href: "https://t.me/beeproxies_support",
    icon: FaTelegramPlane,
    color: "#229ED9"
  },
];