import { FC, useMemo } from "react";

interface FooterProps {}

const Footer: FC<FooterProps> = () => {
  const year = useMemo(() => {
    const date = new Date();
    return date.getFullYear();
  }, []);

  return (
    <footer
      className={
        "flex w-full items-center gap-6 bg-neutral-900 p-4 text-sm text-neutral-300 shadow-md z-50"
      }
    >
      <div className="text-center w-full">
        <span className="font-bold">BeeProxies</span> &copy; {year}
      </div>
    </footer>
  );
};

export default Footer;
