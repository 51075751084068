import Form from "@/components/Form";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { FC } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

interface OrderModalProps {}

const CheckoutModal: FC<OrderModalProps> = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleOpenChange = (open: boolean) => {
    if (!open) return navigate("/");
  };

  if (!state) {
    return <Navigate to="/" />;
  }

  return (
    <Dialog onOpenChange={handleOpenChange} defaultOpen={true}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Checkout</DialogTitle>
        </DialogHeader>
        <Form.Checkout
          defaultValues={{
            ...state,
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CheckoutModal;
