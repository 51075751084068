import { FC } from "react";
import { PLANS } from "@/config";
import Card from "@/components/Card";

interface PlansProps {}

const Plans: FC<PlansProps> = () => {
  return (
    <>
      <section className="bg-white" data-navlink="Plans">
        <div className="mx-auto max-w-7xl px-4 py-12 text-slate-800">
          <div className="mb-8 flex flex-col items-start justify-between gap-4 md:flex-row md:items-end md:px-8">
            <h2 className="max-w-lg text-4xl font-bold md:text-5xl text-primary-content">
              Check out
              <span className="text-secondary-dark"> our plans</span>
            </h2>
          </div>
          <div className="flex flex-col lg:flex-row gap-8 lg:gap-4 w-full max-w-6xl mx-auto relative z-10">
            {PLANS.map((plan, index) => (
              <Card.Plan key={["plan", index].join(":")} {...plan} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Plans;
