import { FC, FormEventHandler, useMemo, useState } from "react";
import { FiCheck, FiTrash } from "react-icons/fi";
import clsx from "clsx";
import { useFormContext } from "react-hook-form";
import api from "@/services/api";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Button } from "../ui/button";

interface DiscountCodeProps {}

const DiscountCode: FC<DiscountCodeProps> = () => {
  const [inputValue, setInputValue] = useState<string>("");
  const { register, watch, setValue, resetField } = useFormContext();

  register("discount");
  register("discountCode");
  const discount = watch("discount");

  const icon = useMemo(() => {
    const Icon = discount ? FiTrash : FiCheck;
    return <Icon size={24} />;
  }, [discount]);

  const handleInputChange: FormEventHandler<HTMLInputElement> = ({
    currentTarget,
  }) => {
    setInputValue(currentTarget.value);
  };

  const handleButtonClick = async () => {
    if (!discount) {
      try {
        const { data } = await api.addDiscountCode(inputValue);
        setValue("discount", data.discount_value);
        setValue("discountCode", data.code);
        toast.success(`You got ${data.discount_value.toFixed(0)}% discount 🍻`);
      } catch (e) {
        if (e instanceof AxiosError) {
          const message = e.response?.data?.error || e.message;
          toast.error(message);
        } else {
          toast.error("Unknown error occured: Try again later");
        }
        setInputValue("");
      }
    } else {
      resetField("discount");
      resetField("discountCode");
      setInputValue("");
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <Label htmlFor="discountCode">Discount Code</Label>
      <div className="flex gap-4">
        <Input
          id="discountCode"
          type="text"
          placeholder="Enter Discount code"
          disabled={discount}
          onInput={handleInputChange}
          value={inputValue}
        />
        <Button
          type="button"
          disabled={!inputValue}
          className={clsx({
            "bg-error": discount,
            "bg-primary disabled:bg-primary/50": !discount,
          })}
          onClick={handleButtonClick}
        >
          {icon}
        </Button>
      </div>
    </div>
  );
};

export default DiscountCode;
