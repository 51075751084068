import { motion } from "framer-motion";
import { FC } from "react";
import { IconType } from "react-icons";

interface DashboardCardProps {
  icon: IconType;
}

export const DashboardCard: FC<DashboardCardProps> = ({ icon: Icon }) => {
  return (
    <div className="group relative mx-auto w-full max-w-sm overflow-hidden rounded-lg bg-primary-content p-0.5 transition-all duration-500 hover:scale-[1.01]">
      <div className="relative z-10 flex items-center justify-center overflow-hidden rounded-[7px] bg-background p-8 transition-colors duration-500">
        <Icon className="relative z-10 mb-10 mt-2 rounded-full border-2 border-primary bg-background p-4 text-7xl text-primary" />
        <div>
          <h4 className="relative z-10 mb-4 w-full text-3xl font-bold text-slate-50">
            Test
          </h4>
          <p className="relative z-10 text-slate-400 w-full">Test</p>
        </div>
      </div>

      <motion.div
        initial={{ rotate: "0deg" }}
        animate={{ rotate: "360deg" }}
        style={{ scale: 1.75 }}
        transition={{
          repeat: Infinity,
          duration: 3.5,
          ease: "linear",
        }}
        className="absolute inset-0 z-0 bg-gradient-to-br from-indigo-200 via-indigo-200/0 to-indigo-200 opacity-0 transition-opacity duration-500 group-hover:opacity-100"
      />
    </div>
  );
};

export default DashboardCard;
