import { FC, PropsWithChildren, useEffect, useState } from "react";
import { useAnimate } from "framer-motion";

interface GridHeroProps extends PropsWithChildren {}

const GridHero: FC<GridHeroProps> = ({ children }) => {
  const [scope, animate] = useAnimate();

  const [size, setSize] = useState({ columns: 0, rows: 0 });

  useEffect(() => {
    generateGridCount();
    window.addEventListener("resize", generateGridCount);

    return () => window.removeEventListener("resize", generateGridCount);
  }, []);

  const generateGridCount = () => {
    const columns = Math.floor(document.body.clientWidth / 75);
    const rows = Math.floor(document.body.clientHeight / 75);

    setSize({
      columns,
      rows,
    });
  };

  const handleMouseLeave = (e: any) => {
    // @ts-ignore
    const id = `#${e.target.id}`;
    animate(id, { background: "rgba(255, 222, 71, 0)" }, { duration: 1.5 });
  };

  const handleMouseEnter = (e: any) => {
    // @ts-ignore
    const id = `#${e.target.id}`;
    animate(id, { background: "rgba(255, 222, 71, 0.7)" }, { duration: 0.15 });
  };

  return (
    <section className="bg-secondary-light relative">
      <span className="absolute -top-[150px] left-[50%] h-[200px] w-[250px] md:-top-[350px] md:left-[50%] z-0 md:h-[500px] md:w-[600px] -translate-x-[50%] rounded-full bg-gradient-to-r from-primary/20 to-primary/30 blur-3xl" />
      <div
        ref={scope}
        className="grid h-screen w-full grid-cols-[repeat(auto-fit,_minmax(75px,_1fr))] grid-rows-[repeat(auto-fit,_minmax(75px,_1fr))] z-10"
      >
        {[...Array(size.rows * size.columns)].map((_, i) => (
          <div
            key={i}
            id={`square-${i}`}
            onMouseLeave={handleMouseLeave}
            onMouseEnter={handleMouseEnter}
            className="h-full w-full border-[1px] border-neutral-900"
          />
        ))}
      </div>
      <div className="pointer-events-none absolute inset-0 flex flex-col items-center justify-center p-8">
        {children}
      </div>
    </section>
  );
};

export default GridHero;
