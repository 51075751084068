import { FaBitcoin, FaCheckCircle } from "react-icons/fa";
import { motion } from "framer-motion";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { GLOBALS } from "@/config";
import { FC } from "react";
import usePrice from "@/hooks/usePrice";
import FormItem from "@/components/FormItem";
import api from "@/services/api";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Alert, AlertTitle } from "../ui/alert";
import { Button } from "../ui/button";
import { Slider } from "../ui/slider";

interface CheckoutFormInputs {
  email: string;
  size: number;
  discount?: number;
  discountCode?: string;
}

interface CheckoutFormProps {
  defaultValues?: CheckoutFormInputs;
}

const CheckoutForm: FC<CheckoutFormProps> = ({ defaultValues }) => {
  const methods = useForm<CheckoutFormInputs>({
    defaultValues,
  });
  const { register, watch, control, setValue, formState, handleSubmit } =
    methods;

  const size = watch("size");
  const discount = watch("discount");

  const price = usePrice(size, discount);

  const onSubmit = async ({
    email,
    size,
    discountCode,
  }: CheckoutFormInputs) => {
    try {
      const { data } = await api.createOrder({
        email,
        size,
        discount_code: discountCode,
      });
      toast.success("Order successfully created!");
      toast.success("You will be redirected to our payment processor");
      setTimeout(() => {
        window.location.replace(data.payment_url);
      }, 3000);
    } catch (e) {
      if (e instanceof AxiosError) {
        const message = e.response?.data?.error || e.message;
        toast.error(message);
      } else {
        toast.error("Unknown error occured: Please try again");
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
        {/* Service Duration */}
        <Alert className="py-6">
          <AlertTitle className="flex justify-between">
            <span>To pay</span>
            <span className="text-primary">{price.toCurrency()}</span>
          </AlertTitle>
        </Alert>
        <div className="flex flex-col gap-3">
          <Label htmlFor="email">Email</Label>
          <Input
            id="email"
            type="email"
            placeholder="your@email.com"
            {...register("email", {
              required: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Please insert a valid email address",
              },
            })}
          />
        </div>
        <FormItem.DiscountCode />
        <div className="flex flex-col gap-3">
          <Label>Payment Method</Label>
          <Button
            variant="outline"
            className="border-primary flex gap-3"
            type="button"
          >
            <FaBitcoin size={24} />
            <span>Cryptocurrency</span>
          </Button>
        </div>
        <div className="flex flex-col gap-3">
          <Label htmlFor="size" className="flex justify-between items-center">
            <span>Bandwidth</span>
            <span className="text-md text-primary">{size} GBs</span>
          </Label>
          <Controller
            name="size"
            control={control}
            render={({ field }) => {
              const props = register("size", {
                required: true,
                valueAsNumber: true,
              });

              return (
                <Slider
                  {...props}
                  id="size"
                  defaultValue={[field.value]}
                  min={GLOBALS.BANDWIDTH.MIN}
                  max={GLOBALS.BANDWIDTH.MAX}
                  onValueChange={([value]) => setValue("size", value)}
                />
              );
            }}
          />
        </div>
        <div>
          {formState.isSubmitSuccessful ? (
            <motion.button
              type="submit"
              whileHover={{ scale: 1.015 }}
              whileTap={{ scale: 0.985 }}
              className="w-full font-semibold bg-success/60 rounded uppercase py-2 text-white flex items-center justify-center gap-3"
              disabled
            >
              <FaCheckCircle size={16} />
              Redirecting...
            </motion.button>
          ) : (
            <motion.button
              type="submit"
              whileHover={{ scale: 1.015 }}
              whileTap={{ scale: 0.985 }}
              className="w-full font-semibold bg-primary disabled:bg-primary/50 rounded uppercase py-2 text-secondary"
              disabled={!formState.isValid}
            >
              Purchase
            </motion.button>
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export default CheckoutForm;
